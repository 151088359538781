/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { useLocation } from "@reach/router"
 import { useStaticQuery, graphql } from "gatsby"

 const SEO = ({ title, description, image, article }) => {
  // const { pathname } = useLocation()
  // const { site } = useStaticQuery(query)
  // const {
  //   defaultTitle,
  //   titleTemplate,
  //   defaultDescription,
  //   siteUrl,
  //   defaultImage,
  //   twitterUsername,
  // } = site.siteMetadata
  // const seo = {
  //   title: title || defaultTitle,
  //   description: description || defaultDescription,
  //   image: `${siteUrl}${image || defaultImage}`,
  //   url: `${siteUrl}${pathname}`,
  // }
  return null
 }
 
 SEO.propTypes = {
   title: PropTypes.string,
   description: PropTypes.string,
   image: PropTypes.string,
   article: PropTypes.bool,
 }

 SEO.defaultProps = {
   title: null,
   description: null,
   image: null,
   article: false,
 }

 export default SEO
